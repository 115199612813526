type Props = {
  characters: number;
  errors: number;
  timeTaken: number;
};

function Results(props: Props) {
  const wpm = (props.characters / 5 - props.errors) / (props.timeTaken / 60);
  const accuracy = ((props.characters - props.errors) / props.characters) * 100;
  return (
    <div className="flex flex-row gap-3 items-center mb-5 text-xl">
      <p className="bg-neutral-800 px-3 py-2 rounded-full border border-neutral-700 font-bold select-none">
        {wpm > 0 ? Math.round(wpm) : 0} wpm
      </p>
      <p className="bg-neutral-800 px-3 py-2 rounded-full border border-neutral-700 font-bold select-none">
        {accuracy > 0 ? accuracy.toFixed(2) : 0}% accuracy
      </p>
      <p className="bg-neutral-800 px-3 py-2 rounded-full border border-neutral-700 font-bold select-none mr-3">
        {props.errors} errors
      </p>
      <svg
        className="cursor-pointer"
        xmlns="http://www.w3.org/2000/svg"
        onClick={() => {
          window.location.reload();
        }}
        style={{ height: "1.25em", width: "1.25em" }}
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <polyline points="23 4 23 10 17 10" />
        <polyline points="1 20 1 14 7 14" />
        <path d="M3.51 9a9 9 0 0 1 14.85-3.36L23 10M1 14l4.64 4.36A9 9 0 0 0 20.49 15" />
      </svg>
    </div>
  );
}

export default Results;
