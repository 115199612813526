import { useState, useRef, useEffect } from "react";
import { MODES, THEMES } from "../constants/enums";
import Results from "./Results";

type Props = {
  text: string;
  mode: string;
  theme: string;
};

function TypingInput(props: Props) {
  const [position, setPosition] = useState<number>(1);
  const [line, setLine] = useState<number>(0);
  const [start, setStart] = useState<Date>({} as Date);
  const [characters, setCharacters] = useState<number>(0);
  const [words, setWords] = useState<number>(0);
  const [errors, setErrors] = useState<number>(0);
  const [timeoutId, setTimeoutId] = useState<number>();
  const [isFinished, setIsFinished] = useState<boolean>(false);
  const [timeTaken, setTimeTaken] = useState<number>();

  const divRef = useRef<HTMLDivElement>(null);
  const caretRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    if (!divRef.current) return;
    divRef.current.style.height = `${
      (divRef.current!.children[0].children[1] as HTMLElement).offsetHeight *
        3 +
      5
    }px`;
    caretRef.current!.style.top = `${
      divRef.current!.children[0].children[1].getBoundingClientRect().y + 5
    }px`;
    caretRef.current!.style.left = `${
      divRef.current!.children[0].children[1].getBoundingClientRect().x - 3
    }px`;
    caretRef.current!.style.backgroundColor = `white`;
  }, [divRef, caretRef]);

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      const key = event.key;

      if (isFinished) return;

      // avoid spaces in words & typos in spaces
      if (
        key === " " &&
        (divRef.current!.children[0].children[position] as HTMLElement)
          .innerText !== " "
      )
        return;
      if (
        key !== " " &&
        (divRef.current!.children[0].children[position] as HTMLElement)
          .innerText === " "
      )
        return;

      // remove caret blinking
      caretRef.current!.classList.remove("blink");

      // move caret
      if (position < props.text.length && line < 1)
        caretRef.current!.style.top = `${
          divRef.current!.children[0].children[
            position + 1
          ].getBoundingClientRect().y + 5
        }px`;
      if (position < props.text.length)
        caretRef.current!.style.left = `${
          divRef.current!.children[0].children[
            position + 1
          ].getBoundingClientRect().x
        }px`;
      else caretRef.current!.style.display = "none";

      // move to new line
      if (
        position < props.text.length &&
        (divRef.current!.children[0].children[position] as HTMLElement)
          .offsetTop !==
          (divRef.current!.children[0].children[position + 1] as HTMLElement)
            .offsetTop
      ) {
        if (line > 0)
          (divRef.current!.children[0] as HTMLElement).style.marginTop = `-${
            (divRef.current!.children[0].children[1] as HTMLElement)
              .offsetHeight * line
          }px`;
        setLine((line) => line + 1);
      }

      if (
        key ===
        (divRef.current!.children[0].children[position] as HTMLElement)
          .innerText
      ) {
        if (key === " ") {
          setWords((words) => words + 1);
        }

        // turn character white
        divRef.current!.children[0].children[position].classList.add("typed");
        divRef.current!.children[0].children[position].classList.add(
          props.theme
        );
      } else {
        // wrong key pressed / error
        if (position <= props.text.length) {
          divRef.current!.children[0].children[position].classList.add(
            props.theme === THEMES.Classic ? "wrong-red" : "wrong-white"
          );
          setErrors((errors) => errors + 1);
        }
      }

      // start of text
      if (position === 1) {
        setStart(new Date());
        if (props.mode === MODES.Time) {
          setTimeout(() => {
            setIsFinished(true);
          }, 100000);
        }
      }

      // end of text
      if (position === props.text.length) {
        setIsFinished(true);
        const timeTaken: number =
          (new Date().getTime() - start.getTime()) / 1000;
        setTimeTaken(timeTaken);
      }

      setPosition((position) => position + 1);
      setCharacters((characters) => characters + 1);
    };

    const handleKeyDown = (event: KeyboardEvent) => {
      const key = event.key;

      if (isFinished) return;

      if (event.ctrlKey && key === "Backspace") {
        // delete whole word
      }

      // delete last character
      if (key === "Backspace" && position > 1) {
        caretRef.current!.style.top = `${
          divRef.current!.children[0].children[
            position - 1
          ].getBoundingClientRect().y + 5
        }px`;
        caretRef.current!.style.left = `${
          divRef.current!.children[0].children[
            position - 1
          ].getBoundingClientRect().x
        }px`;
        divRef.current!.children[0].children[position - 1].classList.remove(
          props.theme === THEMES.Classic ? "wrong-red" : "wrong-white"
        );
        divRef.current!.children[0].children[position - 1].classList.remove(
          "typed"
        );
        divRef.current!.children[0].children[position - 1].classList.remove(
          props.theme
        );
        setPosition((position) => position - 1);
      }
    };

    window.addEventListener("keypress", handleKeyPress);
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keypress", handleKeyPress);
      window.removeEventListener("keydown", handleKeyDown);
      clearTimeout(timeoutId);
    };
  }, [
    position,
    line,
    props.text.length,
    start,
    props.mode,
    characters,
    errors,
    isFinished,
    props.theme,
    timeoutId,
  ]);

  return (
    <>
      {!isFinished && (
        <div
          ref={divRef}
          className="w-3/5 overflow-hidden outline-none"
          tabIndex={0}
        >
          <p className="text-3xl font-sans text-neutral-600 select-none">
            <span
              ref={caretRef}
              className="caret blink border-r-2 border-white text-3xl absolute"
              style={{ height: "1em" }}
            ></span>
            {props.text.split("").map((char: string, index: number) => (
              <span key={`${char}-${index}`}>{char}</span>
            ))}
          </p>
        </div>
      )}
      {!isFinished && (
        <div className="absolute bottom-4 right-4 border border-gray-600 rounded-full py-1 px-3 transition-opacity opacity-50 hover:opacity-80 select-none">
          <p>
            {props.mode === MODES.Words
              ? `${words}/${props.text.split(" ").length}`
              : `1:00`}
          </p>
        </div>
      )}
      {isFinished && (
        <Results
          characters={characters}
          errors={errors}
          timeTaken={timeTaken!}
        ></Results>
      )}
    </>
  );
}

export default TypingInput;
