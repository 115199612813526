import React, { useState } from 'react';
import Input from './components/Input';
import Navigation from './components/Navigation';
import { MODES, THEMES } from './constants/enums';
import { generateText } from './constants/words';


function App() {
  const [nrOfWords, setNrOfWords] = useState<number>(parseInt(JSON.parse(localStorage.getItem('nrOfWords')!)) || 50);
  const [text, setText] = useState<string>(generateText(nrOfWords));
  const [mode, setMode] = useState<string>(JSON.parse(localStorage.getItem('mode')!) || MODES.Words);
  const [theme, setTheme] = useState<string>(JSON.parse(localStorage.getItem('theme')!) || THEMES.Classic)

  function changeNrOfWords(amount : number) {
    localStorage.setItem('nrOfWords', JSON.stringify(amount))
    setNrOfWords(amount)
    window.location.reload()
  }

  function changeMode(mode : string) {
      localStorage.setItem('mode', JSON.stringify(mode))
      setMode(mode)
      window.location.reload()
  }

  function changeTheme(theme : string) {
    localStorage.setItem('theme', JSON.stringify(theme))
    setTheme(theme)
    window.location.reload()
  }

  return (
    <div className="w-full h-full flex flex-col justify-center items-center bg-neutral-900">
        <Navigation changeNrOfWords={changeNrOfWords} mode={mode} changeMode={changeMode} changeTheme={changeTheme}></Navigation>
        <Input text={text} theme={theme} mode={mode}></Input>
    </div>

  );
}

export default App;
