export enum MODES {
  Words = "words",
  Time = "time",
}

export enum THEMES {
  Classic = "classic",
  Fire = "fire",
  Rainbow = "rainbow",
}
