import React, { useState, useEffect } from "react";
import { MODES, THEMES } from "../constants/enums";
import Modal from "./Modal";

type Props = {
  changeNrOfWords: Function;
  mode: string;
  changeMode: Function;
  changeTheme: Function;
};

function Navigation(props: Props) {
  const [showThemeModal, setShowThemeModal] = useState<boolean>(false);

  function handleEscapeOnThemeModal(event: KeyboardEvent) {
    if (event.key === "Escape") setShowThemeModal(false);
  }

  useEffect(() => {
    window.addEventListener("keydown", handleEscapeOnThemeModal);
    return () =>
      window.removeEventListener("keydown", handleEscapeOnThemeModal);
  }, []);

  function handleWordAmountClick() {
    const nrOfWords: number = parseInt(
      prompt("How many words do you want to write?")!
    );
    if (Number.isNaN(nrOfWords)) alert("You have to put in a number");
    else {
      props.changeNrOfWords(nrOfWords);
    }
  }

  function handleModeClick(mode: string) {
    props.changeMode(mode);
  }

  function handleThemeClick(theme: string) {
    setShowThemeModal(false);
    props.changeTheme(theme);
  }

  return (
    <React.Fragment>
      <nav className="absolute top-4 left-4 flex flex-row gap-1 border border-gray-600 rounded-full py-1 px-3 transition-opacity opacity-20 hover:opacity-80">
        <svg
          className="feather feather-hash h-5 cursor-pointer"
          onClick={() => handleWordAmountClick()}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <title>Nr. of Words</title>
          <line x1="4" y1="9" x2="20" y2="9"></line>
          <line x1="4" y1="15" x2="20" y2="15"></line>
          <line x1="10" y1="3" x2="8" y2="21"></line>
          <line x1="16" y1="3" x2="14" y2="21"></line>
        </svg>
        <svg
          className="feather feather-droplet h-5 cursor-pointer"
          onClick={() => setShowThemeModal(true)}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <title>Theme</title>
          <path d="M12 2.69l5.66 5.66a8 8 0 1 1-11.31 0z"></path>
        </svg>
        {props.mode === MODES.Words ? (
          <svg
            onClick={() => handleModeClick(MODES.Time)}
            className="feather feather-clock h-5 cursor-pointer"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <title>Change to time mode</title>
            <circle cx="12" cy="12" r="10"></circle>
            <polyline points="12 6 12 12 16 14"></polyline>
          </svg>
        ) : (
          <svg
            onClick={() => handleModeClick(MODES.Words)}
            className="feather feather-align-left h-5 cursor-pointer"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <title>Change to word mode</title>
            <line x1="17" y1="10" x2="3" y2="10"></line>
            <line x1="21" y1="6" x2="3" y2="6"></line>
            <line x1="21" y1="14" x2="3" y2="14"></line>
            <line x1="17" y1="18" x2="3" y2="18"></line>
          </svg>
        )}
      </nav>
      {showThemeModal && (
        <Modal>
          <div className="grid grid-cols-3 gap-3">
            <div
              onClick={() => handleThemeClick(THEMES.Classic)}
              className="bg-neutral-900 text-white px-12 py-4 rounded-lg text-center select-none cursor-pointer hover:opacity-90"
            >
              classic
            </div>
            <div
              onClick={() => handleThemeClick(THEMES.Fire)}
              className="text-white px-12 py-4 rounded-lg text-center select-none cursor-pointer hover:opacity-90"
              style={{
                background: "linear-gradient(to right,#b31313,#ff9000,#fdda16)",
              }}
            >
              fire
            </div>
            <div
              onClick={() => handleThemeClick(THEMES.Rainbow)}
              className="text-white px-12 py-4 rounded-lg text-center select-none cursor-pointer hover:opacity-90"
              style={{
                background:
                  "linear-gradient(to right,#ED5565,#FFCE54,#A0D468,#4FC1E9)",
              }}
            >
              rainbow
            </div>
          </div>
        </Modal>
      )}
    </React.Fragment>
  );
}
export default Navigation;
