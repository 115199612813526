type Props = {
  children: React.ReactNode;
};

function Modal(props: Props) {
  return (
    <div
      style={{ backdropFilter: "blur(10px)" }}
      className="fixed left-0 top-0 w-full h-full flex flex-col justify-center items-center z-30"
    >
      <div className="bg-neutral-800 w-fit p-5 rounded-lg z-50">
        {props.children}
      </div>
    </div>
  );
}

export default Modal;
